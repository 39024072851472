
.home-h2{
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 1em;
    line-height: 1.35;
    color: #4B4D75;

    @media (max-width: 1159px){
        font-size: 28px;
    }
    @media (max-width: 767px){
        font-size: 22px;
    }
}
.th-16-1{
    min-height: 42.69vw;
    position: relative;
    display: flex;
}
.th-16-1-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
}
.th-16-1-text{
    background: rgba(0, 0, 0, 0.35);
    padding: 5em 2em 5em 3.6em;
    margin-left: 11.2vw;
    position: relative;
    z-index: 1;
    width: 80%;
    max-width: 38em;
    color: #ffffff;
    font-size: clamp(9px, 1.1vw, 18px);

    @media (max-width: 575px) {
        margin-left: 0;
        width: 100%;
        max-width: inherit;
        font-size: 12px;
        padding: 4em 2em 4em 2em;
    }
    > h1{
        font-weight: 700;
        font-size: 4.5em;
        margin-bottom: 0.385em;
        text-transform: uppercase;
        line-height: 1.16;

        &:lang(hy){

            @media (min-width: 576px){
                font-size: 3.5em;
            }
        }
        @media (max-width: 575px){
            font-size: 2.5em;
        }
    }
    > p{
        font-size: 1.5em;
        margin-bottom: 0;
    }
}

.th-16-2{
    padding: 36px 0;

    @media (max-width: 767px){
        padding: 24px 0 12px;
    }
}
.th-16-2-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #4B4D75;

    @media (max-width: 767px){
        flex-direction: column;
    }
}
.th-16-2-left {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 48%;
    padding: 12px 0;
    max-width: 500px;

    @media (max-width: 767px){
        width: 100%;
    }
    > p{
        font-size: 20px;
        line-height: 1.6;

        @media (max-width: 1159px){
            font-size: 18px;
            line-height: 1.5;
        }
    }
}
.th-16-2-right {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 50%;
    padding: 12px 0;

    @media (max-width: 767px){
        width: 100%;
    }
}
.th-16-2-left-item {
    padding: 24px 12px;
    width: 48%;
    text-align: center;

    @media (max-width: 1159px){
        padding: 24px 6px;
    }
    @media (max-width: 767px){
        padding: 24px 0;
    }
    img{
        height: 71px;
        margin-bottom: 18px;
        width: auto;
        object-fit: contain;

        @media (max-width: 1159px){
            margin-bottom: 12px;
        }
    }
    p{
        font-size: 20px;
        margin-bottom: 0;

        @media (max-width: 1159px){
            font-size: 18px;
        }
    }
}

.th-16-3{
    padding: 60px 0;
    background: #FDE7DF;

    @media (max-width: 767px){
        padding: 36px 0;
    }
}
.th-16-3-container{
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px){
        flex-direction: column-reverse;
    }
}
.th-16-3-img{
    padding: 12px 0;
    width: 437px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;

    @media (max-width: 1159px){
        width: 350px;
    }
    @media (max-width: 767px){
        width: 100%;
        max-width: 420px;
    }
    > img{

        &:nth-child(1){
            width: 255px;
            height: 260px;
        }
        &:nth-child(2){
            margin-left: 86px;
            width: 255px;
            height: 427px;
            margin-top: 23px;

            @media (max-width: 1159px){
                margin-left: 24px;
            }
        }
        &:nth-child(3){
            position: absolute;
            top: 95px;
            right: 0;
            width: 160px;
            height: 354px;
        }
    }
}
.th-15-3-text{
    flex-grow: 1;
    max-width: 557px;
    padding: 12px 0 12px 36px;
    color: #4B4D75;

    @media (max-width: 1159px){
        max-width: 370px;
    }
    @media (max-width: 767px){
        max-width: 420px;
        padding: 12px 0 12px 0;
    }
    > p{
        font-size: 20px;
        line-height: 1.6;

        @media (max-width: 1159px){
            font-size: 18px;
            line-height: 1.4;
        }
    }
    ul{
        font-size: 20px;
        font-weight: 700;
        margin-top: 48px;
        margin-bottom: 0;
        padding-left: 30px;

        @media (max-width: 1159px){
            padding-left: 22px;
            line-height: 1.2;
            margin-top: 30px;
            margin-bottom: 0;
            font-size: 18px;
        }
        li{
            margin-top: 16px;
        }
    }
}

.th-16-4{
    padding: 48px 0 16px;

    .home-h2{
        margin-bottom: 0;
    }
}

.th-16-5{
    background: #F3F2F9;
    padding: 12px 0 48px;

    @media (max-width: 767px){
        padding: 6px 0 36px;
    }
}

.th-16-6{
    padding: 48px 0;

    @media (max-width: 767px){
        padding: 24px 0;
    }
}
.th-16-6-bg{
    background: #FDE7DF;
    height: 150px;
    left: 0;
    position: absolute;
    top: 85px;
    width: 100%;

    @media (max-width: 1159px){
        height: 128px;
        top: 80px;
    }
    @media (max-width: 767px){
        top: 73px;
        height: 110px;
    }
}
.th-16-6-container{
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-left: 60px;

    @media (max-width: 1159px){
        padding-left: 48px;
    }
    @media (max-width: 767px){
        flex-direction: column-reverse;
        padding-left: 0;
        max-width: 420px;
        margin: 0 auto;
    }
}
.th-16-6-img{
    padding: 12px 0;
    position: relative;
    z-index: 1;
    width: 345px;
    flex: 0 0 345px;

    @media (max-width: 1159px) {
        width: 280px;
        flex: 0 0 280px;
    }
    @media (max-width: 767px){
        width: 100%;
        flex: 0 0 100%;
    }
}
.th-16-6-text{
    padding: 12px 0 12px 36px;
    max-width: 662px;
    position: relative;
    z-index: 1;
    color: #4B4D75;
    flex-grow: 1;

    @media (max-width: 1159px){
        padding: 12px 0 12px 24px;
    }
    @media (max-width: 767px){
        padding: 12px 0 12px 0;
    }
    figure{
        display: flex;
        align-items: center;
        margin-top: 60px;
        margin-bottom: 60px;

        @media (max-width: 1159px){
            margin-top: 48px;
            margin-bottom: 48px;
        }
        @media (max-width: 767px){
            padding-left: 15px;
        }
        > img{
            width: 92px;
            height: 92px;
            border-radius: 50%;

            @media (max-width: 767px){
                width: 70px;
                height: 70px;
            }
        }
    }
    figcaption{
        font-size: 24px;
        padding-left: 36px;
        font-family: 'SegoePrint', serif;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 1159px){
            padding-left: 20px;
            font-size: 20px;
        }
        @media (max-width: 767px){
            padding-left: 12px;
            font-size: 18px;
        }
    }
    > p{
        font-size: 20px;
        margin-bottom: 0;
        line-height: 1.6;

        @media (max-width: 1159px){
            font-size: 18px;
            line-height: 1.5;
        }
    }
}

.th-16-7{
    padding: 48px 0;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 767px){
        padding: 36px 0;
    }
    .home-h2{
        margin-bottom: 36px;
        display: flex;
        align-items: center;

        img{
            margin-right: 18px;
        }
    }
}
.th-16-7-gb{
    position: absolute;
    left: 0;
    height: 26%;
    width: 100%;
    background: #FDE7DF;
}
.th-16-7-container{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}
.th-16-7-item{
    display: flex;
    margin-bottom: 48px;
    max-width: 813px;
    width: 100%;

    @media (max-width: 767px){
        flex-direction: column;
        max-width: 420px;
        margin: 0 auto 36px;
    }
    &:nth-child(even){
        margin-left: auto;
        margin-bottom: 0;
    }
    > img{
        width: 255px;
        flex: 0 0 255px;
        height: 255px;
        object-fit: cover;

        @media (max-width: 767px){
            width: 100%;
            flex: 0 0 100%;
        }
    }
}
.th-16-7-item-info{
    padding: 24px;
    background-color: #F7F7FF;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    p{
        color: #555557;
        margin-bottom: auto;
        padding-bottom: 15px;
    }
    h3{
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 0;
    }
    h4{
        color: #030303;
        font-size: 16px;
        margin-top: 3px;
        margin-bottom: 0;
    }
}
.theme-faq{
    color: #4B4D75;
    padding: 72px 0;
    position: relative;
    background: #F3F2F9;

    @media (max-width: 767px){
        padding: 48px 0;
    }
    > img{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
        z-index: -1;
    }
    .home-static-container > p{
        font-size: 18px;
    }
}
.th-16-8{
    padding: 48px 0;

    @media (max-width: 767px){
        padding: 36px 0;
    }
}
.th-16-8-container{
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    color: #4B4D75;
}
.th-16-8-item{
    align-items: flex-start;
    display: flex;
    margin: 24px 0;
    max-width: 540px;
    padding: 12px 0;
    width: 49%;

    @media (max-width: 767px){
        width: 100%;
        margin: 6px 0;
    }
    > img{
        width: 58px;
        flex: 0 0 58px;

        @media (max-width: 1159px){
            width: 46px;
            flex: 0 0 46px;

        }
    }
}
.th-16-8-item-text{
    padding-left: 24px;
    flex-grow: 1;

    @media (max-width: 1159px){
        padding-left: 15px;
    }
    h3{
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 12px;
    }
    p{
        font-size: 18px;
        margin-bottom: 0;

        @media (max-width: 1159px){
            font-size: 16px;
        }
    }
}

.theme-16-form{
    padding: 48px 0;
    background: rgba(79, 14, 9, 1);
    color: #ffffff;

    .theme-all-form-bg{
        opacity: .55;
    }
    .home-h2{
        color: #ffffff;
    }
}
