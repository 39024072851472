
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-300.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-regular.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-500.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-700.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/roboto-v30-latin-900.woff2') format('woff2');
}

@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 300;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-300.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 400;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-regular.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 500;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-500.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 600;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-600.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 700;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-700.woff2') format("woff2");
}

body{
    font-family: Roboto, NotoSansArmenian, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@import 'home';
@import '../../global/tenants-global';


@font-face {font-family: "SegoePrint";
    src: url("//db.onlinewebfonts.com/t/3e7c392e6314d3043afade17def0975c.eot");
    src: url("//db.onlinewebfonts.com/t/3e7c392e6314d3043afade17def0975c.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/3e7c392e6314d3043afade17def0975c.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/3e7c392e6314d3043afade17def0975c.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/3e7c392e6314d3043afade17def0975c.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/3e7c392e6314d3043afade17def0975c.svg#SegoePrint") format("svg");
}


:root {
    --nav-background: #ffffff;
    --nav-link: #000000;
    --nav-link-hover: #FA7B70;


    --btn1-background-color: #FA7B70;
    --btn1-background-hover-color: #D36157;
    --btn1-text-color: #ffffff;
    --btn1-text-hover-color: #ffffff;

    --btn1-outline-background-color: #ffffff;
    --btn1-outline-background-hover-color: #FA7B70;
    --btn1-outline-text-color: #FA7B70;
    --btn1-outline-text-color-hover: #ffffff;
    --btn1-outline-border-color: #FA7B70;
    --btn1-outline-border-hover-color: #FA7B70;


    --btn2-background-color: #D36157;
    --btn2-background-hover-color: #c15950;
    --btn2-text-color: #ffffff;
    --btn2-text-hover-color: #ffffff;

    --btn2-outline-background-color: #ffffff;
    --btn2-outline-background-hover-color: #D36157;
    --btn2-outline-text-color: #D36157;
    --btn2-outline-text-color-hover: #ffffff;
    --btn2-outline-border-color: #D36157;
    --btn2-outline-border-hover-color: #D36157;


    --color-1: #D36157;
    --color-2: #D36157;


    --course-background: #ffffff;
    --course-background-hover: #fde7df;
    --course-color: #000000;
    --course-color-hover: #000000;
    --course-color-hr: #ffffff;
    --course-color-hr-hover: #ffffff;
    --course-border: transparent;
    --course-radius: 4px;

}
h1,h2,h3,h4,h5,h6, p{
    min-height: 17px;
}

.btn{
    border-radius: 70px;
    font-size: 18px;
    padding: 0.55em 1.4em;
    letter-spacing: 0;
}

.home-course-item-block hr{
    display: none;
}
.home-course-section > h2{
    font-weight: 700;
    font-size: 36px;
}
.blog-block{

    &:hover{

        .blog-text{
            background-color: #fde7df;
        }
    }
}


.bg-landing{

    .landing-container-full{
        background: #FDE6DF;
        color: #4B4D75;
    }
}

.theme-all-info-section-1{
    background-color: #FDE6DF;
    color: #4B4D75;
    padding: 68px 20px 72px;
}
.theme-all-info-section-2-container{
    color: #4B4D75;
}
.landing-container-full-text > h2{
    font-weight: 700;
}
.theme-16-info-section-new{
    padding: 35px 12px;

    @media (max-width: 767px){
        padding: 25px 12px;
    }

    .theme-all-info-section-1-container, .theme-all-info-section-2-container{
        padding: 35px 12px;

        @media (max-width: 767px){
            padding: 25px 12px;
        }
    }
}
